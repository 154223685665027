
import sodium from "libsodium-wrappers";

export async function encryptArrayBuffer(buffer: ArrayBuffer, key: Uint8Array, nonce: Uint8Array): Promise<Uint8Array> {
    await sodium.ready;
    const keyUint8 = sodium.crypto_generichash(32, key);
    const encrypted = sodium.crypto_secretbox_easy(new Uint8Array(buffer), nonce, keyUint8);

    return encrypted;
}

export async function decryptArrayBuffer(encrypted: Uint8Array, nonce: Uint8Array, key: Uint8Array): Promise<ArrayBufferLike | null> {
    await sodium.ready;
    const keyUint8 = sodium.crypto_generichash(32, key);
    const decrypted = sodium.crypto_secretbox_open_easy(encrypted, nonce, keyUint8);

    return decrypted.buffer ?? null;
}